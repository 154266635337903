<template>
  <div>
    <!-- MODAL para adicionar novo de/para -->
    
    <b-modal
      :id="modalDag.id"
      ref="modalExecutar"
      :title="modalDag.title"
      size="lg"
      hide-footer
      no-close-on-backdrop
     @close="hideModalExecutar()"
    >
    <b-overlay
      :show="show"
      rounded
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block"
    >  
      

        <validation-observer ref="newRules">
          <b-form>
            <b-row>
              <b-col cols="12">
                <!-- Cod Empresa -->

                <b-form-group
                  label="Cod Empresa"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Cod Empresa"
                  >
                    <v-select
                      v-model="selected_empresa"
                      placeholder="Selecione Empresa"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="options_empresa"
                      :state="errors.length > 0 ? false:null"
                      @input="getEstab()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

                <!-- Cod Estabelecimento -->
              <b-col cols="12">   
                <b-form-group
                  label="Cod Estabelecimento"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Cod Estabelecimento"
                  >
                    
                      <div class="checkbox-group-container">

                        <b-form-checkbox-group
                          v-if="selected_empresa"
                          v-model="selected_estab"
                          :options="options_estab"
                          stacked
                        />

                      </div>
                      <div class="mt-2 form-check">
                        <b-form-checkbox
                          v-model="allSelected"
                          :indeterminate="indeterminate"
                          @change="toggleAll"
                        >
                          Select All
                        </b-form-checkbox>
                      </div>

                    
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <div
                    v-if="selected_empresa"
                    class="mt-2"
                  >
                    Selected: <strong>{{ selected_estab }}</strong>
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                    label="Data Início"
                    class="mt-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Data Início"
                    >
                      <b-input-group class="">
                        <b-form-input
                          id="valueDataIni"
                          v-model="valueDataIni"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="on"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            id="valueDataIni"
                            v-model="valueDataIni"
                            button-only
                            right
                            locale="pt"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
              
              <b-col cols="12">
                 <!-- Data Fim -->
                 <b-form-group
                    label="Data Fim"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Data Fim"
                    >
                      <b-input-group class="">
                        <b-form-input
                          id="valueDataFim"
                          v-model="valueDataFim"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="off"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            id="valueDataFim"
                            v-model="valueDataFim"
                            button-only
                            right
                            locale="pt"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                      <!-- botão FILTRO -->
                  
                  <b-form-checkbox
                    v-model="filtroChecked"
                    :indeterminate="indeterminate"
                    @change="onFiltroChange()"
                  >
                    Filtros
                  </b-form-checkbox>

                   <!-- botão coluna -->
                  
                  <b-form-group
                    class="mt-2"
                  >
                      <v-select
                        v-if="filtroChecked"
                        v-model="selected_safxcol"
                        :options="options_safxcol"
                        placeholder="Coluna"
                        :disabled="isLoading || options_safxcol.length === 0"
                                            
                      />

                    </b-form-group>
                    
                    <!-- Lista de campos e botões -->
                    <div v-if="selected_safxcol && filtroChecked">
                      <!-- Loop para exibir os campos e botões -->
                      <div 
                        v-for="(input, index) in inputs" 
                        :key="index" 
                        class="mt-2 d-flex flex-column" 
                      >
                        <div class="d-flex align-items-center">
                          <b-form-input
                            id="filtroInput"
                            v-model="input.value"
                            placeholder="Filtro"
                            size="sm"
                            style="max-width: 300px"
                          ></b-form-input>

                          <!-- Botão "menos" para remover o campo -->
                          <b-button
                            variant="primary"
                            size="sm"
                            class="ms-2"
                            @click="removeInput(index)"
                          >
                            -
                          </b-button>
                        </div>

                        <!-- Botão "+" para adicionar outro campo -->
                        <div class="mt-2" v-if="index === inputs.length - 1">
                          <b-button
                            :disabled= "!input.value"
                            variant="primary"
                            size="sm"
                            class="ms-2"
                            @click="addInput"
                          >
                            +
                          </b-button>
                        </div>
                      </div>

                      
                      <!-- Exibir todos os valores concatenados -->
                      <div class="mt-2">
                        Filtros: [{{ inputs.map(input => `'${input.value}'`).join(', ') }}]
                      </div>
                    </div>

                </b-col>
                 
                
              <b-col class="demo-inline-spacing">
                <b-button
                  id=""
                  variant="primary"
                  type="button"
                  @click="hideModalExecutar()"
                >
                  Cancelar
                </b-button>

                <!-- Adicionar Nova Regra button -->
                
                  <b-button
                    id="executButton"
                    variant="primary"
                    type="button"
                    @click="validationFormExecutar"
                  >
                    Executar
                  </b-button>

                  
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        
      
    </b-overlay>
    </b-modal>
  
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import {
  BFormTextarea, BButton, BModal, BFormDatepicker, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BForm, BRow, BCol, BOverlay, BInputGroup, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ModalExecutar',
  components: {
    BFormTextarea,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormDatepicker,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BForm,
    BRow,
    BCol,
    BButton,
    BModal,
  },
  data() {
    return {
      filtros: {
        coluna: '',
        filtro: [],
      },
      isLoading: true, // Estado de carregamento
      selected_safxcol: null,
      selected: [],
      filtroChecked:false, //estado checkbox
      inputs: [],// Array inicial de inputs
      allSelected: false,
      indeterminate: false,
      modalDag: {
        id: 'modalDag',
        title: '',
        content: '',
      },
      show: false,
      selected_empresa: '',
      selected_estab: [],
      valueDataIni: '',
      valueDataFim: '',
      id_conversion: null,
      readonly: true,
      options_empresa: [
        { value: null, label: 'Selecione a tabela' },
      ],
      options_estab: [
        { value: null, label: 'Selecione a tabela' },
      ],
      options_safxcol: [
        { value: null, label: 'Selecione a coluna' },
      ],
      required,
    }
  },
  // Select all checkbox (button)
  watch: {
    // Observa a seleção no v-select
    selected_safxcol(newValue) {
      if (newValue) {
        // Adiciona o primeiro campo automaticamente ao selecionar no v-select
        this.inputs = [{ value: "" }]
      } else {
        this.inputs = []
      }
    },
    selected_estab(newValue) {
      // Handle changes in individual checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.options_estab.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
 
  created() {
    this.getEmpresaTab()
    
  },
  // mounted() {
  //   // Chama getSafxCol ao carregar o componente
  //   this.getSafxCol()
    
  // },
  methods: {
    addInput() {
      // Adiciona um novo campo ao array
      this.inputs.push({ value: "" })
    },

    removeInput(index) {
      console.log("chamou o método remove Input")
      if (this.inputs.length > 1) {
        this.inputs.splice(index, 1) // Remove o campo pelo índice
      }
    },

    async onFiltroChange() {
      if (this.filtroChecked) {
        await this.getSafxCol() // Chama o método ao ativar o filtro
      } else {
        // Reseta os campos ao desmarcar o checkbox
        this.selected_safxcol = null 
        this.inputs = []
      }
    },
    async rulesTable() {
      this.isBusy = true
      try {
        const selectedCnpj = this.$parent.$refs.cboxclient.selected
        console.log("selectedCnpj Modal", selectedCnpj)
        if (selectedCnpj === null) {
          this.toast('b-toaster-top-right', true, 'danger', 'Selecione um cliente!', 'Erro de usabilidade')
          throw new Error('Selecione um cliente!')
        }
        const res = await axios.get(`/custom_conversion/${selectedCnpj}`)
        this.items = res.data
        // this.totalRows = this.btable_rule_items.length
        // this.currentPage = 1
        console.log(res.data)
        console.log(res.data[0].id_safx)
        return res.data[0].id_safx
        
      } catch (error) {
        console.log(error)
      }
      this.isBusy = false
    },
    async getSafxCol() {
      try {
      this.isLoading = true
      // Obter id_safx chamando toTakeIdSafx
      const idSafx = await this.rulesTable()
      console.log("idSafx", idSafx)
      if (!idSafx) {
        console.error("id_safx não encontrado.")
        return
      }

      console.log("Obtendo colunas para id_safx:", idSafx)
      const responseFiltered = []
      const resSafxCol = await axios.get(`/safx_col/${idSafx}`)

      resSafxCol.data.forEach(item => {
        responseFiltered.push({
          value: item.id_safxcol,
          label: `${item.col_name}`,
        })
      })

        // Atualiza as opções no dropdown

      this.options_safxcol = responseFiltered
      console.log(responseFiltered)
      // this.totalRows = this.btable_rule_items.length
      // this.currentPage = 1
      } catch (error) {
        console.error("Erro ao buscar colunas:", error)
      } finally {
        this.isLoading = false // Finaliza o estado de carregamento
      }
    },
    toggleAll(checked) {
      this.selected_estab = checked ? this.options_estab.map(option => option.value) : []
    },
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },

    validationFormAgendar() {
      this.$refs.newRules.validate().then(success => {
        if (success) {
          this.postAgendamento()
          // hide (fechar o modal após o item ser postado)
        }
      })
    },

    validationFormExecutar() {
      this.$refs.newRules.validate().then(success => {
        if (success) {
          this.postExecucao()
        }
      })
    },
    
    postExecucao() {
      this.show = true // loading page b-overlay
      try {
        // Getting values for POST
        const cnpjSelected = this.$parent.$refs.cboxclient.selected
        console.log(`${cnpjSelected}cnpj`)
        const safxSelected = this.modalDag.title
        console.log(`${safxSelected}safxSelected`)

        const empresaSelected = this.selected_empresa.value.COD
        console.log(`${empresaSelected}empresaSelected`)

        const estabValues = this.selected_estab.map(String)
        console.log(`${estabValues}estabSelected`)

        const cdataIni = this.valueDataIni.replace(/-/g, '')
        console.log(`${cdataIni}cdataIni`)

        const cdataFim = this.valueDataFim.replace(/-/g, '')
        console.log(`${cdataFim}cdataFim`)

        const user = JSON.parse(localStorage.getItem('userData'))?.username || "username não disponível"
        console.log(user)

        const coluna = this.selected_safxcol?.label || ''
        console.log(coluna)
        
        const filtro = this.inputs
        .map(input => input.value.trim()) // Remove espaços em branco
        .filter(value => value); // Remove strings vazias ou valores falsy (como null e undefined)

        if (filtro.length === 0) {
          console.warn('Nenhum filtro válido foi fornecido!');
        }

        // Corpo da requisição
        const requestBody = {
          SAFX: safxSelected,
          COD_EMPRESA: empresaSelected,
          COD_ESTAB: estabValues,
          DATA_INI: cdataIni,
          DATA_FIM: cdataFim,
          USER: user,
          filtros: {
            coluna: coluna,
            filtro: filtro
          }
        }

        console.log("requestBody:", requestBody)
        // End getting values for post

        // Enviar requisição com axios
        axios.post(`/airflow/interfaceRunV2/${cnpjSelected}`, requestBody)
          .then(() => {
            this.toast('b-toaster-top-right', true, 'success', `Execução efetuada com sucesso`, 'Execução SAFX')
            // Fechar o modal após o item ser postado
            this.show = false // loading page b-overlay
            this.$refs.modalExecutar.hide()
            this.$parent.safxTable1()
          })
          .catch(error => {
            console.error("Erro na resposta da API:", error.response ? error.response.data : error); // Mostra o erro de resposta para ver o que está causando o 422
          })
          
        // Limpar campos após a execução
          this.selected_empresa = ''
          this.selected_estab = []
          this.valueDataIni = ''
          this.valueDataFim = ''
          this.filtroChecked = false
          this.selected_safxcol = ''
          this.inputs.input = []

          console.log(this.selected_safxcol,this.inputs.input ) // Deve exibir: { coluna: '', filtro: [] }

      } catch (error) {
        console.log(error)
      }
      this.show = false
    },
    async getEstab() {
      this.show = true // loading page b-overlay
      try {
        // Reset selected_estab when a new company is selected
        this.selected_estab = []
        const responseFiltered = []
        const getEstabFromEmpr = this.selected_empresa.value.CNPJ
        console.log(getEstabFromEmpr)
        const resEstab = await axios.get(`/branch/${getEstabFromEmpr}`)

        resEstab.data.forEach(item => {
          responseFiltered.push({
            value: item.cod,
            label: `${item.cod} - ${item.name}`,
            text: `${item.cod} - ${item.name}`,
          })
        })
        this.options_estab = responseFiltered.sort((a, b) => {
          const codA = parseInt(a.value) // Converte o 'cod' para inteiro
          const codB = parseInt(b.value)
          return codA - codB // Ordenação crescente
       })
        // this.totalRows2 = this.row.item.runs.length
        // this.currentPage2 = 1


      } catch (error) {
        console.log(error)
      }
      this.show = false
    },
    hideModalExecutar() {
      this.selected_empresa = ''
      this.selected_estab = []
      this.valueDataIni = ''
      this.valueDataFim = ''
      this.$refs.modalExecutar.hide()
    },
    async getEmpresaTab() {
      try {
        const responseFiltered = []
        const getCnpj = this.$parent.$refs.cboxclient.selected
        const resEmpresa = await axios.get(`/company/${getCnpj}`)
        console.log(resEmpresa)
        resEmpresa.data.forEach(item => {
          responseFiltered.push({
            value: { CNPJ: item.id_cnpj, COD: item.cod },
            label: `${item.id_cnpj} - ${item.cod} -${item.name}`,
          })
        })
        this.showEstab = true
        this.options_empresa = responseFiltered
      } catch (error) {
        console.log(error)
      }
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.checkbox-group-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;

}
</style>
