<!-- eslint-disable vue/no-template-shadow -->
<template>

  <div>
    <!-- ComboBox -->
    <b-row>
      <b-col cols="4">
        <b-form-group
          description="Selecione o CNPJ."
        >
          <combo-box-client ref="cboxclient" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <b-button
            variant="primary"
            type="button"
            @click="safxTable1()"
          >
            Carregar
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- END ComboBox -->
    <b-row>
      <b-col
        md="2"
        sm="4"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        sm="8"
        class="my-1"
      >
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">
                  -- none --
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="my-1"
      >
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-overlay
          :show="show"
          spinner-variant="primary"
          spinner-type="border"
          spinner-large
          rounded="lg"
        >
          <!-- Table 1 -->
          <b-table
            id="bTable"
            ref="firstTable"
            striped
            hover
            responsive
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            :items="itemsTab1"
            :fields="fieldsTab1"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <!-- coluna actions botões -->
            <template #cell(actions)="row">

              <!-- Expandir Detalhes Button -->
              <b-button
                size="sm"
                class="mr-1"
                @click="toggleDetailsAndSafxTable2(row)"
              >
                {{ row.detailsShowing ? '-' : '+' }}
              </b-button>

              <!-- Executar Button -->

              <b-button
                size="sm"
                @click.prevent="modalExecut(row.item, $event.target)"
              >
                Executar
              </b-button>
            </template>
            <!-- Fim Executar Button -->

            <!-- Table 2  -->
            <template #row-details="row">
              <b-table
                ref="runs"
                :current-page="row.item.currentPage2"
                :fields="fieldsTab2"
                :items="row.item.runs"
                :per-page="perPage"
              >

                <!-- Barra de Status -->
              <template #cell(status)="row">
                  <b-progress
                    v-b-tooltip.hover.topright="row.item.task_error"
                    :max="max"
                  >
                    <b-progress-bar
                      variant="danger"
                      :value="row.item.task_failed"
                    />

                    <b-progress-bar
                      variant="success"
                      :value="row.item.task_success"
                    />

                  </b-progress>
                </template>
                
                <template #cell(actions)="row">
                  <b-button size="sm" @click="modalTable3(row, row.index, $event.target)" class="mr-1">
                    Info
                   </b-button>
                </template>

                <!-- end Table 2 -->
                // eslint-disable-next-line vue/no-template-shadow
               
                

              </b-table>
              <!-- Pagination tabela dentro da tabela 2 -->
              <b-pagination
                ref="pagination"
                v-model="row.item.currentPage2"
                :total-rows="100"
                :per-page="perPage"
                :align="center"
                size="sm"
                class="my-0"
              />
               <!-- modal tabela 3-->
              <b-modal 
              :id="modalTab3.id" 
              :title="modalTab3.title" 
              hide-footer
              size="xl"
              no-close-on-backdrop
              >

              <b-table
                ref="modalProcess"
                :current-page="currentPage2"
                :fields="fieldsTab3"
                :items="modalProcess"
                :per-page="perPage"
              >

              <!-- Barra de Status -->
              <template #cell(status)="row">
                  <b-progress
                    v-b-tooltip.hover.topright="row.item.task_error"
                    :max="max"
                  >
                    <b-progress-bar
                      variant="danger"
                      :value="row.item.task_failed"
                    />

                    <b-progress-bar
                      variant="success"
                      :value="row.item.task_success"
                    />

                  </b-progress>
                </template>
            
            </b-table>
                 <!-- Pagination tabela dentro da tabela 3 -->
                <b-pagination
                ref="pagination"
                v-model="currentPage2"
                :total-rows="100"
                :per-page="perPage"
                :align="center"
                size="sm"
                class="my-0"
              />
              </b-modal>
            </template>
          </b-table>
        </b-overlay>
        <!-- End Table -->
        <div>

          <modal-view-log ref="mviewlog" />
          <!-- Modal botão executar -->
          <modal-executar ref="mexecutar" />
          <modal-tabela-agend ref="modalTabAg" />
          <validate-logon-keycloak ref="validatelogon" />
        </div>
      </b-col>
      <b-col
        cols="12"
      >
         <!-- Pagination tabela dentro da tabela 3 -->
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-button
      variant="outline-primary"
      @click="handleClick()"
    >
      Agendamento execução
    </b-button>
  </div>
</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import ModalViewLog from '@/views/processamento/ModalViewLog.vue'
import ModalExecutar from '@/views/processamento/ModalExecutar.vue'
import ComboBoxClient from '@/views/reusable-components/ComboBoxClient.vue'
import ModalTabelaAgend from '@/views/processamento/ModalTabelaAgend.vue'
import {
  VBTooltip,
  BProgress,
  BProgressBar,
  BOverlay,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'
import ValidateLogonKeycloak from '../reusable-components/ValidateLogonKeycloak.vue'

export default {
  name: 'Processamento',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BProgress,
    BProgressBar,
    ModalTabelaAgend,
    ModalViewLog,
    ModalExecutar,
    ComboBoxClient,
    BOverlay,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidateLogonKeycloak,
  },
  data() {
    return {
      modalProcess: [],
      visible: true,
      taskArrays: [],
      max: 9,
      row:"",
      rolesNeeded: ['full'],
      values: '',
      show: false,
      perPage: 25,
      pageOptions: [5, 10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      currentPage2: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      selected: null,
      options: [
        { value: null, text: 'Carregando' },
      ],
      codEmpresa: {
        id: 'cod-empresa',
      },
      tabAgendamento: {
        id: 'tabAgendamento',
        title: '',
        content: '',
      },
      modalTab3: {
        id: 'modalTab3',
        title: '',
        content: '',
      },
      modalDag: {
        id: 'modalDag',
        title: '',
        content: '',
      },
      modalLog: {
        id: 'modalLog',
        title: '',
        content: '',
      },
      fieldsTab2: [
        //{
         // key: 'numProcesso', label: 'Processo', sortable: true,
        //},
        {
          key: 'codEmpresa', label: 'Cod Empresa', sortable: true,
        },
        {
          key: 'dataIni', label: 'Data Inicio', sortable: true,
        },
        {
           key: 'dataFim', label: 'Data Fim', sortable: true,
        },
        {
          key: 'start_date', label: 'Inicio Execução', sortable: true,
        },
        {
          key: 'end_date', label: 'Fim Execução', sortable: true,
        },
        {
          key: 'estabs', label: 'Qtd Estabs', sortable: true,
        },
        {
          key: 'user', label: 'User', sortable: true,
        },
        {
          key: 'status', label: 'Status', sortable: true,
        },
        {
          key: 'errors', label: 'Registros com erro', sortable: true,
        },
        {
          key: 'rows', label: 'Registros', sortable: true,
        },
         //{
         // key: 'task_success', label: 'Registros', sortable: true,
         //},
        // {
         //  key: 'task_failed', label: 'Registros', sortable: true,
         //},
         //{
          // key: 'task_error', label: 'Registros', sortable: true,
         //},
        { key: 'actions', label: 'Actions' },

      ],
      fieldsTab1: [
        {
          key: 'dag_id', label: 'Interface', sortable: true,
        },
        {
          key: 'start_date', label: 'Last Run', sortable: true,
        },
        {
          key: 'status', label: 'Status', sortable: true,
        },
        { key: 'actions', label: 'Actions' },
      ],
      fieldsTab3: [
        
        {
          key: 'conf.codEstab', label: 'Cod Estab', sortable: true,
        },
        {
         key: 'start_date', label: 'Inicio Execução', sortable: true,
        },
        {
          key: 'end_date', label: 'Fim Execução', sortable: true,
        },
        {
          key: 'status', label: 'Status', sortable: true,
        },
        {
          key: 'errors', label: 'Registros com erro', sortable: true,
        },
        {
          key: 'rows', label: 'Registros', sortable: true,
        },
      ],
      itemsTab1: [],
      itemsRuns: [],
      
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() { // Mounted has to be used when using a Child and trigger after the import
    this.validateUserLogon()
  },
  methods: {
    
    handleClick(formattedCron) {
      // Chama os dois métodos desejados
      const selectedCnpj = this.$refs.cboxclient.selected
      this.$refs.modalTabAg.tabAgendamento.title = selectedCnpj
      console.log(selectedCnpj)
      this.$refs.modalTabAg.agTable(formattedCron)
      console.log('chamou agTable')
      this.modalTabAgendamento()
      console.log('chamou modal')
    },

    validateUserLogon() {
      this.$refs.validatelogon.validateLogon()
    },
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    // trazendo dados das safx
    async safxTable1() {
      this.show = true // loading page b-overlay
      try {
        const selectedCnpj = this.$refs.cboxclient.selected
        if (selectedCnpj === null) {
          this.toast('b-toaster-top-right', true, 'danger', 'Selecione um cliente!', 'Erro de usabilidade')
          throw new Error('Selecione um cliente!')
        }

        const res = await axios.get(`/airflow/getInterfacesV2/${selectedCnpj}`)
        this.itemsTab1 = res.data
        this.totalRows = this.itemsTab1.length
        this.currentPage = 1
        console.log(res.data)
      } catch (error) {
        console.log(error)
      }

      this.show = false
    },
    toggleDetailsAndSafxTable2(row) {
      this.show = true
      console.log('SafxTable2')
      // Chama o método toggleDetails da linha
      row.toggleDetails()

      const dagId = row.item.dag_id
      console.log(dagId)
      const offset = 0
      const selectedCnpj = this.$refs.cboxclient.selected

      // Supondo que `item` tenha informações necessárias para a chamada

      axios.get(`/airflow/getAirflowByProcess/${selectedCnpj}_${dagId}/${offset}`)
        
          .then(res => {
            // Popula os dados de 'runs' na linha clicada
            this.$set(row.item, 'runs', res.data)
            console.log('Updated runs:', row.item.runs)
            
            // Atualiza o totalRows com base na resposta do servidor
            this.show = false;
        })
        .catch(error => {
          console.error('Erro ao obter dados da API:', error)
          this.show = false
        })

        .catch(error => {
          console.error('Erro ao obter status das tarefas:', error)
          this.show = false
        })
    },
    modalTable3(row, target, button) {
      this.show = true
      console.log(row)
      
      const selectedCnpj = this.$refs.cboxclient.selected

      const dagId = row.item.SAFX
      console.log('dagId:', dagId)

      const numProcesso = row.item.numProcesso
      console.log('numProcesso:', numProcesso)
      
      const offset = 0
      
      // Supondo que `item` tenha informações necessárias para a chamada
      console.log('Iniciando chamada para a API:', `/airflow/getAirflowDagRunV3/${selectedCnpj}_${dagId}/${numProcesso}/${offset}`)
      axios.get(`/airflow/getAirflowDagRunV3/${selectedCnpj}_${dagId}/${numProcesso}/${offset}`)
        
      .then(res => {
          console.log('API Full Response:', res); // Log completo da resposta
          console.log('res.data:', res.data);     // Dados da resposta

          // Use Vue.set to ensure reactivity when adding a new property
          //this.$set(row.item, 'modalProcess', res.data)

          this.modalProcess = [...res.data]; // Garantir reatividade
          this.currentPage2 = 1 // Reiniciar a paginação se necessário
          
          // Update totalRows based on the response from the server
          console.log('Updated row.item:', row.item)
          console.log('Updated res.data:', res.data)

          this.$root.$emit('bv::show::modal', this.modalTab3.id, button)
          this.show = false
          
        })
      
        .catch(error => {
          console.error('Erro ao obter status das tarefas:', error)
          this.show = false
        })
        
    },
    modalExecut(item, button) {
      this.show = true // loading page b-overlay
      this.$refs.mexecutar.modalDag.title = `${item.dag_id}`
      this.$refs.mexecutar.getEmpresaTab()
      this.$root.$emit('bv::show::modal', this.modalDag.id, button)// Faz o modal aparecer
      this.show = false
    },
    modalViewL(item, button) {
      const selectedCnpj = this.$refs.cboxclient.selected
      this.show = true // loading page b-overlay
      this.$refs.mviewlog.modalLog.title = item.dag_id
      this.$refs.mviewlog.getLogs(selectedCnpj, item.dag_id, item.dag_run_id)
      this.$root.$emit('bv::show::modal', this.modalLog.id, button)// Faz o modal aparecer
      this.show = false
    },
    modalTabAgendamento(button) {
      const selectedCnpj = this.$refs.cboxclient.selected

      if (!selectedCnpj) {
        // Se selectedCnpj for null, exibe uma mensagem usando b-toast
        this.$bvToast.toast('Por favor, selecione um CNPJ.', {
          title: 'Aviso',
          variant: 'warning',
          autoHideDelay: 5000, // Tempo em milissegundos (opcional)
        })

        // Aborta a execução do restante do método
        return
      }

      // Se selectedCnpj não for null, continua com a lógica normal
      this.show = true // loading page b-overlay
      this.$refs.modalTabAg.tabAgendamento.title = selectedCnpj
      this.$root.$emit('bv::show::modal', this.$refs.modalTabAg.tabAgendamento.id, button) // Faz o modal aparecer
      this.show = false
    },

  },
}
</script>
